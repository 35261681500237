import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import NosModules  from  "../components/Wispeek/NosModules"
import Offres  from  "../components/Wispeek/Offres"
import ContentTwo  from  "../components/Wispeek/ContentTwo"
import { Helmet } from "react-helmet"

const Wispeek = () => {
  return (
    <Layout>
      <Helmet>
        <title>Seekreet | Solution SaaS Wispeek de conformité Compliance qualité QVCT</title>
        <meta name="description" content="Wispeek by Seekreet. Vous cherchez une solution SAAS de mise en conformité Compliance ? Gestion qualité ? Obtenir une certification ISO ou FSSC ? Nous avons la solution, simple ergonomique nous vous..." />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Wispeek" 
          homePageText="Accueil" 
          homePageUrl="/" 
          activePageText="Wispeek" 
      />
      <NosModules />
      <Offres />
      <ContentTwo />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default Wispeek