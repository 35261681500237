import React from 'react'
import { Link } from 'gatsby'
import shape1 from '../../assets/images/services/service-shape1.png'
import shape2 from '../../assets/images/services/service-shape2.png'
import fleche from '../../assets/images/fleche.png'
//import complianceIcon from '../../assets/images/icones/compliance-icon.gif'
//import qhseIcon from '../../assets/images/icones/qhse-icon.gif'
//import fscIcon from '../../assets/images/icones/fsc-icon.gif'
//import qvctIcon from '../../assets/images/icones/qvct-icon.gif'
//import relextIcon from '../../assets/images/icones/relext-icon.gif'

const NosModules = () => {
    return (
        <section className="services-area ptb-100 bg-wispeek">
            <div className="container">
                <div className="section-title">
                    <h2>Centralisez le pilotage de vos procédures organisationnelles et opérationnelles sur un seul outil</h2>
                    <p>Valorisez les compétences de vos équipes et optimisez leur temps en automatisant les tâches administratives chronophages pour gagner en efficacité.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-lg-20p">
                        <div className="single-services-item">
                            <h3 className='ptb-15'>
                                <Link to="/compliance">
                                    <span className='color-compliance'>
                                    Compliance</span>
                                </Link>
                            </h3>
                            <p>Développez une fonction compliance éthique à long terme</p>

                            <div className="shape1">
                                <img src={shape1} alt="" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12 col-lg-20p">
                        <div className="single-services-item">
                            
                            <h3>
                                <Link to="/qhse">
                                    <span className='color-qhse'>
                                    QHSE / Qualité</span>
                                </Link>
                            </h3>
                            <p>Améliorez l’efficacité de votre système de management</p>
                            
                            <div className="shape1">
                                <img src={shape1} alt="" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 col-lg-20p">
                        <div className="single-services-item">
                            
                            <h3>
                                <Link to="/food-safety-culture">
                                    <span className='color-food-safety'>
                                    Food Safety Culture</span>
                                </Link>
                            </h3>
                            <p>Maîtrisez votre culture de la qualité et sécurité des aliments.</p>

                            
                            <div className="shape1">
                                <img src={shape1}  alt="" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12 col-lg-20p">
                        <div className="single-services-item">
                            
                            <h3>
                                <Link to="/qvct">
                                    <span className='color-qvct'>
                                    QVCT / <br></br>DUERP</span>
                                </Link>
                            </h3>
                            <p>Obtenez les feedbacks de l'ensemble de vos collaborateurs</p>
                            
                            
                            <div className="shape1">
                                <img src={shape1} alt="" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12 col-lg-20p">
                        <div className="single-services-item">
                            
                            <h3 >
                                <Link to="/relation-externe">
                                    <span className='color-relext'>
                                    Relation Externe</span>
                                </Link>
                            </h3>
                            <p>Centralisez les données de tous vos tiers</p>
                            
                    
                            <div className="shape1">
                                <img src={shape1} alt=""  />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='center ptb-70'>
                    <img src={fleche} alt="fleche" />
                </div>
                
            </div>
        </section>
    )
}

export default NosModules